<template>
  <div class="flex flex-col mx-4">
    <div class="overflow-x-auto">
      <button
        @click="openAddQueue(null)"
        type="button"
        class="
          mb-1
          float-right
          relative
          inline-flex
          items-center
          px-3
          py-2
          border border-transparent
          shadow-sm
          text-sm
          rounded-md
          text-white
          bg-gray-700
          hover:bg-gray-800
          focus:outline-none
        "
      >
        Add Queue
      </button>

      <div class="py-2 align-middle inline-block min-w-full">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  User
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  Name
                </th>
                <th scope="col" class="relative px-6 py-3">
                  <ClockIcon
                    v-if="loading"
                    class="animate-spin h-4. w-5 text-gray-700"
                    aria-hidden="true"
                  />
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <QueueItem
                @edit="openAddQueue"
                v-for="queue in queues"
                :key="queue.id"
                :queue="queue"
              />
            </tbody>
          </table>

          <div v-if="queues.length === 0" class="mx-3 mb-2">
            <EmptyTable label="You do not have any queues." />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <Pagination />
    </div>
  </div>

  <AddQueue
    ref="addQueue"
    :open="addQueueOpened"
    @close="addQueueOpened = false"
  />
</template>

<script>
import { ClockIcon } from "@heroicons/vue/solid";
import QueueItem from "@/modules/queues/views/queue_item.vue";
import Pagination from "@/modules/queues/views/queue_pagination.vue";
import AddQueue from "@/modules/queues/views/add_queue.vue";
import EmptyTable from "@/widgets/common/EmptyTable.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ClockIcon,
    QueueItem,
    Pagination,
    EmptyTable,
    AddQueue,
  },
  data() {
    return {
      addQueueOpened: false,
    };
  },
  created() {
    this.listQueues();
  },
  methods: {
    openAddQueue(data) {
      this.$refs.addQueue.reset(data);
      this.addQueueOpened = true;
    },
    ...mapActions("queues", ["listQueues"]),
  },
  computed: {
    ...mapGetters("queues", ["queues", "loading"]),
  },
};
</script>
