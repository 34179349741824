<template>
  <tr>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {{ queue.user }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex items-center">
        <div>
          <div class="text-sm font-medium text-gray-900">
            {{ queue.name }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
      <a
        :href="`queue/${queue.id}`"
        class="text-indigo-600 hover:text-indigo-900 mx-8"
        >View</a
      >
      <a @click="edit" href="#" class="text-indigo-600 hover:text-indigo-900"
        >Edit</a
      >
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    queue: {
      type: Object,
      default: null,
    },
  },
  emits: ["edit"],
  methods: {
    edit() {
      this.$emit("edit", this.queue);
    },
  },
};
</script>
