<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      static
      class="fixed inset-0 overflow-hidden"
      @close="deleteOpen ? null : $emit('close')"
      :open="open && !deleteOpen"
    >
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="
              absolute
              inset-0
              bg-gray-500 bg-opacity-75
              transition-opacity
            "
          />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  py-6
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <DialogTitle class="text-lg font-medium text-gray-900">
                      {{ this.isEdit ? "Edit" : "Add New" }} Queue
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        class="
                          bg-white
                          rounded-md
                          text-gray-400
                          hover:text-gray-500
                          focus:outline-none
                        "
                        @click="$emit('close')"
                      >
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mt-2 relative flex-1">
                  <!-- Replace with your content -->
                  <div class="">
                    <form
                      class="h-full divide-y divide-gray-200 flex flex-col"
                      @submit="save"
                    >
                      <div class="flex-1 h-0 overflow-y-auto">
                        <div class="flex-1 flex flex-col justify-between">
                          <div class="px-4 divide-y divide-gray-200 sm:px-6">
                            <div class="space-y-6 pt-6 pb-5">
                              <div>
                                <label
                                  for="name"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-900
                                  "
                                >
                                  Queue Name *
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="name"
                                    id="name"
                                    placeholder="Queue 1"
                                    type="text"
                                    class="
                                      block
                                      w-full
                                      shadow-sm
                                      sm:text-sm
                                      focus:ring-indigo-500
                                      focus:border-indigo-500
                                      border-gray-300
                                      rounded-md
                                    "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="errors.length !== 0" class="bg-red-50 p-4">
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <XCircleIcon
                              class="h-5 w-5 text-red-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div class="ml-3">
                            <h3 class="text-sm font-medium text-red-800">
                              There were errors with your submission
                            </h3>
                            <div class="mt-2 text-sm text-red-700">
                              <ul class="list-disc pl-5 space-y-1">
                                <li v-for="error in errors" :key="error">
                                  {{ error }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="success" class="rounded-md bg-green-50 p-4">
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <CheckCircleIcon
                              class="h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div class="ml-3">
                            <h3 class="text-sm font-medium text-green-800">
                              {{ success }}
                            </h3>
                          </div>
                        </div>
                      </div>

                      <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                        <button
                          @click="deleteOpen = true"
                          v-if="isEdit"
                          type="button"
                          class="
                            inline-flex
                            items-center
                            justify-center
                            px-4
                            py-2
                            border border-transparent
                            font-medium
                            rounded-md
                            text-red-700
                            bg-red-100
                            hover:bg-red-200
                            focus:outline-none
                          "
                        >
                          Delete
                        </button>
                        <button
                          :disabled="loading"
                          type="submit"
                          class="
                            ml-4
                            inline-flex
                            justify-center
                            py-2
                            px-4
                            border border-transparent
                            shadow-sm
                            text-sm
                            font-medium
                            rounded-md
                            text-white
                            bg-indigo-600
                            hover:bg-indigo-700
                            focus:outline-none
                          "
                        >
                          {{ loading ? "Wait.." : "Save" }}
                        </button>
                      </div>
                    </form>
                  </div>

                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <QueueDelete :id="id ?? 0" :open="deleteOpen" @close="addClosed" />
</template>

<script>
import QueueDelete from "@/modules/queues/views/delete.vue";
import StorageUtil from "@/utils/LocalStorageUtil.js";

import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XCircleIcon, CheckCircleIcon } from "@heroicons/vue/solid";
import { XIcon } from "@heroicons/vue/outline";
import queuesApi from "@/network/refactored/queues.js";
import { mapActions } from "vuex";
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    XCircleIcon,
    CheckCircleIcon,
    QueueDelete,
  },

  emits: ["close"],

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      id: 0,
      isEdit: false,
      loading: false,
      errors: [],
      success: null,

      name: "",

      deleteOpen: false,
      selectedEndpoint: 0,
    };
  },

  methods: {
    addClosed(removed) {
      this.deleteOpen = false;
      if (removed) {
        this.$emit("close");
      }
    },

    async reset(data) {
      this.id = data?.id ?? 0;
      this.loading = false;
      this.errors = [];
      this.success = null;

      this.isEdit = !!data;

      this.name = data?.name ?? "";
    },

    async save(e) {
      e.preventDefault();
      if (!this.validate()) return;

      this.loading = true;
      try {
        if (this.isEdit) {
          const { data } = await queuesApi.update(this.id, this.name);
          this.success = "Queue updated successfully";
          this.updateQueue({ queue: data });
        } else {
          const { data } = await queuesApi.createQueue(this.name);
          this.addQueue({ queue: data });
          await this.reset();
          this.success = "Queue added successfully";
        }
      } catch (error) {
        if (error.response) {
          this.errors.push(error.response.data["message"]);
        } else {
          this.errors.push("Error while trying to submit data");
        }
      } finally {
        this.loading = false;
      }
    },

    validate() {
      this.errors = [];
      this.success = null;

      let isValid = true;
      if (this.name === "") {
        isValid = false;
        this.errors.push("Enter a valid Queue name");
      }

      return isValid;
    },

    ...mapActions("queues", ["addQueue", "updateQueue"]),
  },

  computed: {
    userType() {
      return StorageUtil.getUserType();
    },
  },
};
</script>
